import React from "react";
import classnames from "classnames";

const CardFull = ({ className, children }) => {
    return (
        <div className={classnames("xs:h-auto lg:h-16 bg-white px-4 py-2 w-full flex items-center", className)}>
            {children}
        </div>
    );
};
export default CardFull;
