import React, { useState } from "react";
import Card from "../../../components/Cards";
import Layout from "../../../components/Layout";
import SEO from "../../../components/SEO";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "gatsby";
import CardFull from "../../../components/Cards/CardFull";
import { useEffect } from "react";
import requests from "../../../services/requests";

const HelpCenterDetail = props => {
    const identifier = props["*"];
    const [infos, setInfos] = useState([]);

    useEffect(() => {
        requests.getFaq(identifier).then(response => {
            setInfos(response);
        });
    }, []);

    return (
        <Layout>
            <SEO title="Detalhes de help center" />
            <CardFull className="justify-between">
                <h3 className="text-roxo_oficial">Help Center</h3>
            </CardFull>
            <div className="py-8 px-6">
                <Card>
                    <Link to="/help-center" className="cursor-pointer">
                        <IoIosArrowBack className="text-C5 " size="1.5rem" stroke="0.1" />
                    </Link>
                    <div className="mt-20 px-40">
                        {infos.videoUrl && (
                            <iframe
                                width="100%"
                                height="400"
                                src={infos.videoUrl}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            />
                        )}
                        <h1>{infos.title}</h1>
                        <p className="font-normal" dangerouslySetInnerHTML={{ __html: infos.content }} />
                    </div>
                </Card>
            </div>
        </Layout>
    );
};
export default HelpCenterDetail;
